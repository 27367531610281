<template>
  <!-- BEGIN: Notifications -->
  <div class="intro-x dropdown mr-auto sm:mr-6">
    <div
      class="dropdown-toggle notification notification--light cursor-pointer"
      :class="unreads?.length > 0 ? 'notification--bullet' : ''"
      role="button"
      aria-expanded="false"
    >
      <BellIcon class="notification__icon dark:text-gray-300" />
    </div>
    <div class="notification-content pt-2 dropdown-menu">
      <div
        class="notification-content__box dropdown-menu__content box dark:bg-dark-6"
      >
        <div class="notification-content__title flex flex-row">
          <div class="mr-auto">
            Notifications
            <span v-if="unreads?.length > 0">
              ({{ unreads?.length }} unread)</span
            >
          </div>
          <div v-if="unreads?.length > 0" class="pull-right">
            <button class="text-xs text-theme-11" @click="readAll">
              Read all
            </button>
          </div>
        </div>

        <div
          v-for="(notif, key) in unreads"
          :key="key"
          class="cursor-pointer relative flex items-center"
          :class="{ 'mt-3': key }"
        >
          <div class="overflow-hidden">
            <div class="flex items-center">
              <a href="javascript:" class="font-medium truncate mr-5">
                {{ notif?.data?.nama }} ({{ notif?.data?.kode }})
              </a>
              <div class="text-xs text-gray-500 ml-auto whitespace-nowrap">
                {{ $h.timeAgo(notif?.created_at) }}
              </div>
            </div>
            <div class="w-full truncate text-gray-600 mt-0.5">
              {{ notif?.data?.message }}
            </div>
          </div>
        </div>
        <div class="text-center mt-1 border-t border-gray-200 pt-1">
          <router-link :to="{ path: '/notifications' }" class="text-xs">
            Show All Notifications</router-link
          >
        </div>
      </div>
    </div>
  </div>
  <!-- END: Notifications -->
</template>

<script>
import { defineComponent } from "vue";
import store from "@/store";
export default defineComponent({
  name: "Notification",
  props: {
    light: {
      type: String,
      default: "",
    },
  },
  computed: {
    unreads() {
      return this.$store.state.notifications.unreads;
    },
  },
  mounted() {
    store.dispatch("notifications/UnreadNotifications");
  },
  methods: {
    readAll() {
      store.dispatch("notifications/ReadAll");
    },
  },
});
</script>

<style scoped></style>
