<template>
  <!-- BEGIN: Breadcrumb -->
  <div
    :class="
      light === 'light'
        ? '-intro-x breadcrumb breadcrumb--light mr-auto hidden sm:flex'
        : '-intro-x breadcrumb mr-auto hidden sm:flex'
    "
  >
    <div>
      {{ app_title }}
    </div>
    <ChevronRightIcon class="breadcrumb__icon" />
    <router-link :to="{ name: 'dashboard' }" tag="a" class="breadcrumb--active"
      >Dashboard</router-link
    >
  </div>
  <!-- END: Breadcrumb -->
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "BreadCrumb",
  props: {
    light: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      app_title: this.app_title,
    };
  },
  computed: {
    currentFakultas() {
      return this.$store.state.main.currentFakultas;
    },
    currentInstitusi() {
      return this.$store.state.main.currentInstitusi;
    },
  },
});
</script>

<style scoped></style>
