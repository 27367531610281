<template>
  <!-- BEGIN: Search -->
  <div class="intro-x relative mr-3 sm:mr-6">
    <div class="search">
      <input
        type="text"
        class="search__input form-control border-transparent placeholder-theme-13"
        placeholder="Search..."
      />
      <SearchIcon class="search__icon dark:text-gray-300" />
    </div>
  </div>
  <!-- END: Search -->
</template>

<script>
import { defineComponent, ref } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "SearchBar",
  props: {
    light: {
      type: String,
      default: "",
    },
  },
  setup() {
    const store = useStore();
    const searchDropdown = ref(false);

    const showSearchDropdown = () => {
      searchDropdown.value = true;
    };

    const hideSearchDropdown = () => {
      searchDropdown.value = false;
    };

    return {
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown,
      store,
    };
  },
  data() {
    return {
      app_title: this.app_title,
    };
  },
});
</script>

<style scoped></style>
