<template>
  <div :class="topBar">
    <!-- BEGIN: Breadcrumb -->
    <div
      :class="
        light === 'light'
          ? '-intro-x breadcrumb breadcrumb--light mr-auto hidden sm:flex'
          : ' -intro-x breadcrumb mr-auto hidden sm:flex'
      "
    >
      <span>
        {{ app_title }}
      </span>
      <ChevronRightIcon class="breadcrumb__icon" />
      <router-link
        :to="{ name: 'dashboard' }"
        tag="a"
        class="breadcrumb--active"
        >Dashboard</router-link
      >
    </div>
    <!-- END: Breadcrumb -->
    <!-- BEGIN: Search -->
    <div class="intro-x relative mr-3 sm:mr-6">
      <div class="search hidden sm:block">
        <input
          type="text"
          class="search__input form-control border-transparent placeholder-theme-13"
          placeholder="Search..."
        />
        <SearchIcon class="search__icon dark:text-gray-300" />
      </div>
    </div>
    <!-- END: Search -->
    <!-- BEGIN: Notifications -->
    <div class="intro-x dropdown mr-auto sm:mr-6">
      <div
        :class="
          light === 'light'
            ? 'dropdown-toggle notification notification--light notification--bullet cursor-pointer'
            : 'dropdown-toggle notification notification--bullet cursor-pointer'
        "
        role="button"
        aria-expanded="false"
      >
        <BellIcon class="notification__icon dark:text-gray-300" />
      </div>
      <div class="notification-content pt-2 dropdown-menu">
        <div
          class="notification-content__box dropdown-menu__content box dark:bg-dark-6"
        >
          <div class="notification-content__title">Notifications</div>
        </div>
      </div>
    </div>
    <!-- END: Notifications -->
    <!-- BEGIN: Account Menu -->
    <div class="intro-x dropdown w-8 h-8">
      <div
        class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in"
        role="button"
        aria-expanded="false"
      >
        <img
          alt="Icewall Tailwind HTML Admin Template"
          :src="store.state.userdata.userData?.profile_photo_url"
        />
      </div>
      <div id="dropdown-menu" class="dropdown-menu w-56">
        <div
          class="dropdown-menu__content box bg-theme-26 dark:bg-dark-6 text-white"
        >
          <div class="p-4 border-b border-theme-27 dark:border-dark-3">
            <div class="font-medium">
              {{ store.state.userdata.userData?.name }}
            </div>
            <div class="text-xs text-theme-28 mt-0.5 dark:text-gray-600">
              {{
                store.state.userdata.currentTeam?.name +
                " - " +
                store.state.userdata.userData?.uid
              }}
            </div>
          </div>
          <div class="p-2">
            <a
              href=""
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
            >
              <UserIcon class="w-4 h-4 mr-2" /> Profile
            </a>
            <a
              href=""
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
            >
              <LockIcon class="w-4 h-4 mr-2" /> Reset Password
            </a>
          </div>
          <div class="p-2 border-t border-theme-27 dark:border-dark-3">
            <a
              href="#"
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
              @click="logOut"
            >
              <ToggleRightIcon class="w-4 h-4 mr-2" /> Logout
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Account Menu -->
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useStore } from "@/store";
import { mapActions } from "vuex";

export default defineComponent({
  name: "TopBarApp",
  props: {
    light: {
      type: String,
      default: "",
    },
    topBar: {
      type: String,
      default: "",
    },
  },
  setup() {
    const hide = () => {
      cash("#dropdown-menu").dropdown("hide");
    };
    const store = useStore();
    const searchDropdown = ref(false);

    const showSearchDropdown = () => {
      searchDropdown.value = true;
    };

    const hideSearchDropdown = () => {
      searchDropdown.value = false;
    };

    return {
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown,
      store,
      hide,
    };
  },
  data() {
    return {
      app_title: this.app_title,
    };
  },
  computed: {
    currentFakultas() {
      return this.$store.state.main.currentFakultas;
    },
    currentInstitusi() {
      return this.$store.state.main.currentInstitusi;
    },
  },
  methods: {
    ...mapActions({
      LogOut: "auth/LogOut",
    }),
    logOut: function () {
      this.hide();
      this.LogOut();
    },
  },
});
</script>

<style scoped></style>
