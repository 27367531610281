/* eslint-disable */
<template>
  <!-- BEGIN: Top Bar -->
  <div class="top-bar">
    <BreadCrumb />
    <DarkSwitcher />
    <SearchBar />
    <Notification />
    <AccountMenu />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import BreadCrumb from "./BreadCrumb";
import SearchBar from "./SearchBar";
import Notification from "./Notification";
import AccountMenu from "./AccountMenu";
import DarkSwitcher from "@/components/dark-mode-switcher/DarkSwitcher";

export default defineComponent({
  components: {
    DarkSwitcher,
    AccountMenu,
    Notification,
    SearchBar,
    BreadCrumb,
  },
});
</script>
