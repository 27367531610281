<template>
  <Layout>
    <TopMenuMdp></TopMenuMdp>
  </Layout>
</template>

<script>
import Layout from "@/layouts/main/Main";
import TopMenuMdp from "@/layouts/top-menu-mdp/Main";
import SideMenuMdp from "@/layouts/side-menu-mdp/Main";
export default {
  name: "Main",
  components: {
    Layout,
    TopMenuMdp,
  },
};
</script>

<style scoped></style>
