<template>
  <main>
    <slot></slot>
  </main>
</template>

<script>
export default {
  name: "Main",
};
</script>

<style scoped></style>
